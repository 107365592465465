import {
    NewsCarouselConfig,
    NewsCarouselImagePosition,
    NewsCarouselItem,
} from '../components/news-carousel/news-carousel.interface';

export const NEWS_CAROUSEL_ITEMS_EN: NewsCarouselItem[] = [
    {
        heading: 'My Favorite Tools for Web Accessibility Testing',
        textSnippet: 'A selection of bookmarklets, browser extensions and other accessibility testing tools.',
        imageUrl: '/social_sharing_garden_tools.jpg',
        link: '/accessibility-testing-tools.en/',
    },
    {
        heading: 'Accessibility Engineering — Let the Web Platform do the Work',
        textSnippet: 'Harness the power of native buttons, dialogs, form controls, and disclosure widgets.',
        imageUrl: '/social_sharing_code_woman.jpg',
        link: '/accessibility-engineering-web-platform.en/',
    },
    {
        heading: 'Placeholder Text needs Good Color Contrast',
        textSnippet: 'Use the ::placeholder CSS pseudo-element to improve your website\'s accessibility.',
        imageUrl: '/social_sharing_book_rules.jpg',
        imagePosition: NewsCarouselImagePosition.Left,
        link: '/placeholder-text-color-contrast.en/',
    },
    {
        heading: 'How to build Accessible Navigation Menus with the Popover API',
        textSnippet: 'Leveraging the power of modern web features, you can quickly create awesome navigation menus.',
        imageUrl: '/social_sharing_compass.jpg',
        link: '/accessible-navigation-menu.en/',
    },
    {
        heading: 'Easy Offline Storage with idb and the IndexedDB API',
        textSnippet: 'The idb library makes it easy to store data on your device and retrieve it for offline use.',
        imageUrl: '/social_sharing_cardboard_boxes.jpg',
        link: '/offline-storage-indexeddb.en/',
    }
];

export const NEWS_CAROUSEL_ITEMS_DE: NewsCarouselItem[] = [
    {
        heading: 'Meine Lieblings-Tools zum Testen von Web-Accessibility',
        textSnippet: 'Eine Auswahl an Bookmarklets, Browser-Erweiterungen und Tools zum Testen von Barrierefreiheit.',
        imageUrl: '/social_sharing_garden_tools.jpg',
        link: '/de/accessibility-testing-tools.de/',
    },
    {
        heading: 'Accessibility Engineering — Lass die Web-Plattform die Arbeit machen',
        textSnippet: 'Nutze die Fähigkeiten nativer Buttons, Dialoge, Formularelemente und ausklappbarer Widgets.',
        imageUrl: '/social_sharing_code_woman.jpg',
        link: '/de/accessibility-engineering-web-platform.de/',
    },
    {
        heading: 'Platzhaltertext braucht guten Farbkontrast',
        textSnippet: 'Nutze das ::placeholder CSS-Pseudo-Element, um die Barrierefreiheit deiner Website zu verbessern.',
        imageUrl: '/social_sharing_book_rules.jpg',
        imagePosition: NewsCarouselImagePosition.Left,
        link: '/de/placeholder-text-color-contrast.de/',
    },
    {
        heading: 'Barrierefreie Navigationsmenüs mit der Popover API erstellen',
        textSnippet: 'Durch die Nutzung moderner Webfunktionen könnt ihr schnell und einfach Navigationsmenüs bauen.',
        imageUrl: '/social_sharing_compass.jpg',
        link: '/de/accessible-navigation-menu.de/',
    },
    {
        heading: 'Offlinedaten einfach speichern mit idb und der IndexedDB API',
        textSnippet: 'Die idb-Bibliothek macht es einfach, Daten auf dem Gerät zu speichern und sie für die Offline-Nutzung abzurufen.',
        imageUrl: '/social_sharing_cardboard_boxes.jpg',
        link: '/de/offline-storage-indexeddb.de/',
    }
];

export const NEWS_CAROUSEL_CONFIG_EN: NewsCarouselConfig = {
    carouselLabel: 'Recent blog posts',
    carouselDescription: 'carousel',
    slideDescription: 'slide',
    slideLabel: 'of',
    nextButtonLabel: 'Next slide',
    previousButtonLabel: 'Previous slide',
}

export const NEWS_CAROUSEL_CONFIG_DE: NewsCarouselConfig = {
    carouselLabel: 'Aktuelle Blog-Artikel',
    carouselDescription: 'Karussell',
    slideDescription: 'Artikel',
    slideLabel: 'von',
    nextButtonLabel: 'Nächster Artikel',
    previousButtonLabel: 'Vorheriger Artikel',
}
